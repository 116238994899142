// import Vue from 'vue';
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';

const state = {
  camera: {
    id: null,
    client_id: null,
    geo_unit_id: null,
    cameras_model_id: null,
    name: null,
    macaddr: null,
    ip_address: null,
    created_at: null,
    updated_at: null,
    settings: { is_title: false, password: null, username: null },
    status: null,
    status_at: null,
    is_hidden: false,
    channel_id: null,
    uuid: null,
    serial: null,
    fw_ver: null,
    uid: null,
  },
  cameraModel: {},
};

const getters = {
  getCamera() {
    return state.camera;
  },
  getCameraModel() {
    return state.cameraModel;
  },
};

const mutations = {
  setCamera(state, payload) {
    state.camera = payload;
  },

  resetCamera(state) {
    state.camera = Object.assign(
      {},
      {
        id: null,
        client_id: null,
        geo_unit_id: null,
        cameras_model_id: null,
        name: null,
        macaddr: null,
        ip_address: null,
        created_at: null,
        updated_at: null,
        settings: { is_title: false, password: null, username: null },
        status: null,
        status_at: null,
        is_hidden: false,
        channel_id: null,
        uuid: null,
        serial: null,
        fw_ver: null,
        uid: null,
      },
    );
    state.cameraModel = Object.assign({});
  },

  selectCameraModel(state, payload) {
    state.cameraModel = Object.assign({}, payload);
    state.camera.cameras_model_id = payload.id;
  },
};

const actions = {
  validParamsCamera({ commit, dispatch }, payload) {
    return request3.get(
      API_URLS.backendManage.cctv.cameras.devices.validate_params(
        payload.clientId,
      ),
      {
        params: payload.params,
      },
    );
  },

  createCamera({ commit }, payload) {
    console.log(payload);
    return request3
      .post(
        API_URLS.backendManage.cctv.cameras.devices.fetch(payload.clientId),
        {
          cameras_device: payload.cameras_device,
        },
      )
      .then((res) => {
        commit('setCamera', res.data);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
