<template>
  <div>
    <b-modal
      ref="InfoCameraModal"
      :title="$t('devices.modal.create')"
      no-stacking
      @hidden="hide"
    >
      <b-form-validate
        ref="infoForm"
        :model="camera"
        :validations="validations"
      >
        <template slot-scope="{ validateState, v }">
          <div class="modal-body-row with-cols pt-30">
            <b-col cols="12" class="mb-3">
              <geo-units-autocomplete
                v-model="camera.geo_unit_id"
                @clear="camera.geo_unit = {}"
                @update:geoUnit="(value) => (camera.geo_unit = value)"
                :client-id="Number(clientId)"
                :geo-unit="camera.geo_unit"
                :placeholder="$t('devices.modal.address')"
                show-loading
                class="w-100 mb-0 camera-autocompletes"
                section="camera"
                :state="validateState('geo_unit_id')"
                :additional-query-params="{
                  type_not_in: ['City', 'Street', 'District'],
                }"
              ></geo-units-autocomplete>
              <b-error
                v-if="!v.model.geo_unit_id.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
            </b-col>
            <b-col cols="12">
              <b-input-label
                :label="$t('devices.modal.name')"
                v-model="camera.name"
                :state="validateState('name')"
                class="mb-0"
              />
              <b-error
                v-if="!v.model.name.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
              <b-error
                v-if="!v.model.name.maxLength && isSubmit"
                :error="$t('devices.errors.max_length', [70])"
              />
            </b-col>
          </div>
        </template>
      </b-form-validate>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="openSelectCameraModelModal"
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.back') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto"
            @click="create"
          >
            {{ $t('devices.modal.next') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <access-create-camera
      ref="AccessCreateCamera"
      id="camera-access-create"
      :clientId="clientId"
    />
    <error-create-camera
      ref="ErrorCreateCamera"
      id="camera-error-create-modal"
      :clientId="clientId"
    />
  </div>
</template>

<script>
import { GEO_UNITS } from '@/consts';
import { required, maxLength } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import ErrorCreateCamera from '../final/ErrorCreateCamera.vue';
import AccessCreateCamera from '../final/AccessCreateCamera.vue';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import BError from '@/components/base/BError';

export default {
  name: 'InfoCameraModal',
  components: {
    ErrorCreateCamera,
    AccessCreateCamera,
    GeoUnitsAutocomplete,
    BError,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isSubmit: false,
      onHide: false,
    };
  },
  computed: {
    validations() {
      return {
        name: {
          required,
          maxLength: maxLength(70),
        },
        geo_unit_id: {
          required,
        },
      };
    },
    ...mapState('camera', ['camera', 'validateDeviceSetting']),
    ...mapGetters('camera', ['getCamera']),

    camera: {
      get() {
        return this.getCamera;
      },
      set(value) {
        this.setCamera(value);
      },
    },
  },
  methods: {
    ...mapMutations('camera', ['setCamera']),
    ...mapActions('camera', ['validateSettingDevice', 'createCamera']),

    async create(event) {
      if (event) event.preventDefault();
      this.isSubmit = true;

      if (this.$refs.infoForm.validate()) {
        this.onHide = true;
        await this.createCamera({
          cameras_device: this.camera,
          clientId: this.clientId,
        })
          .then(() => {
            this.hide();
            this.$refs.AccessCreateCamera.show();
          })
          .catch(() => this.$refs.ErrorCreateCamera.show());
      }
    },
    show(resetSubmit = true) {
      this.$refs.InfoCameraModal.show();
      this.onHide = false;
      if (resetSubmit) {
        this.isSubmit = false;
      } else {
        if (this.isSubmit) {
          setTimeout(() => {
            this.$refs.InfoCameraModal.validate();
          }, 300);
        }
      }
    },
    hide() {
      if (this.onHide) {
        this.$refs.InfoCameraModal.hide();
      } else {
        this.$bvModal
          .msgBoxConfirm(`${this.$tc('devices.close.create')}`, {
            title: this.$t('devices.close.title'),
            okTitle: this.$t('devices.close.buttons.yes'),
            cancelTitle: this.$t('devices.close.buttons.no'),
            okVariant: 'danger',
            bodyClass: 'modal-body__confirm',
          })
          .then((res) => {
            if (res) {
              this.$refs.InfoCameraModal.hide();
            } else {
              this.onHide = false;
              this.show(false);
            }
          });
      }
    },
    openSelectCameraModelModal() {
      this.onHide = true;
      this.hide();
      this.$emit('showSelectModal');
    },
  },
};
</script>

<style lang="scss">
.camera-autocompletes {
  .autocomplete-results-dropdown {
    margin-bottom: 0 !important;
  }
}
</style>
