import Vue from 'vue';
import { cloneDeep, isEmpty } from 'lodash';

const state = {
  paginations: {},
};

const cleanParams = (query_params) => {
  let params = cloneDeep(query_params);
  if (params.per_page == 1) { delete params['per_page']; };
  if (isEmpty(params.q)) { delete params['q']; };

  return JSON.stringify(params)
};

const getters = {
  getPagination: (state) => (namespace, query_params) => {
    return state.paginations[namespace]?.[cleanParams(query_params)];
  }
};

const mutations = {
  setPagination(state, payload) {
    if (!state.paginations.hasOwnProperty(payload.namespace)) {
      Vue.set(state.paginations, payload.namespace, {});
    };
    Vue.set(
      state.paginations[payload.namespace], cleanParams(payload.query_params), payload.data
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
