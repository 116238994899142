<template>
  <b-modal
    ref="errorsCameraFind"
    :title="$t('devices.modal.create')"
    no-stacking
  >
    <div class="modal-body-row with-cols pt-30">
      <b-form-row>
        <b-col cols="12">
          <b-input-label
            id="select-camera-modal_model_id"
            :value="cameraModel.name"
            :label="$t('devices.modal.model')"
            disabled
          />
        </b-col>
      </b-form-row>
      <b-form-row v-if="cameraModel.mqtt_supported">
        <b-col cols="12" class="mb-3">
          <b-input-label
            disabled
            :label="$t('devices.modal.uuid_or_serial')"
            v-model="camera.uuid_or_serial"
            class="mb-0"
          />
          <span
            class="text-danger small"
            v-for="err in errors.uuid_or_serial"
            :key="err"
          >
            {{ err }}
          </span>
          <span class="text-danger small" v-for="err in errors.uuid" :key="err">
            {{ err }}
          </span>
          <span
            class="text-danger small"
            v-for="err in errors.serial"
            :key="err"
          >
            {{ err }}
          </span>
        </b-col>
      </b-form-row>
      <b-form-row v-if="!cameraModel.mqtt_supported">
        <b-col cols="12" class="mb-3">
          <b-input-label
            disabled
            :label="$t('devices.modal.ip')"
            v-model="camera.ip_address"
            class="mb-0"
          />
          <span
            class="text-danger small"
            v-for="err in errors.ip_address"
            :key="err"
          >
            {{ err }}
          </span>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" class="mb-3" v-if="!cameraModel.mqtt_supported">
          <b-input-label
            v-model="camera.macaddr"
            :label="$t('devices.modal.mac')"
            class="mb-0"
            disabled
          />
          <span
            class="text-danger small"
            v-for="err in errors.macaddr"
            :key="err"
          >
            {{ err }}
          </span>
        </b-col>
      </b-form-row>
      <!-- <b-form-row class="mb-3">
        <b-col cols="12">
          <b-input-label
            :label="$t('devices.tabs.device.username')"
            autocomplete="off"
            v-model="camera.settings.username"
            class="mb-0"
            disabled
          />
        </b-col>
      </b-form-row>
      <b-form-row class="mb-3">
        <b-col cols="12">
          <b-input-label
            :label="$t('devices.tabs.device.password')"
            autocomplete="off"
            v-model="camera.settings.password"
            class="mb-0"
            disabled
          />
        </b-col>
      </b-form-row> -->
      <b-form-row class="mb-3" v-if="cameraModel.channel_id_required">
        <b-col cols="12">
          <b-input-label
            :label="$t('devices.modal.channel')"
            v-model="camera.channel_id"
            class="mb-0"
            disabled
          />
          <span
            class="text-danger small"
            v-for="err in errors.channel_id"
            :key="err"
          >
            {{ err }}
          </span>
        </b-col>
      </b-form-row>
    </div>
    <template #modal-footer>
      <div
        class="d-flex align-items-center justify-content-between flex-grow-1"
      >
        <b-button
          @click="openSelectCameraModelModal"
          size="sm"
          variant="outline-primary"
          class="fast-modal__btn w-auto h-auto"
        >
          {{ $t('devices.modal.back') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="fast-modal__btn w-auto h-auto"
          disabled
        >
          {{ $t('devices.modal.next') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'ErrorsCameraFindModal',
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    ...mapState('camera', ['camera', 'cameraModel']),
    ...mapGetters('camera', ['getCamera', 'getCameraModel']),
    camera: {
      get() {
        return this.getCamera;
      },
      set(value) {
        this.setCamera(value);
      },
    },
    cameraModel() {
      return this.getCameraModel;
    },
  },
  methods: {
    ...mapMutations('camera', ['setCamera']),

    show(errors) {
      this.$refs.errorsCameraFind.show();
      this.errors = errors;
    },
    hide() {
      this.$refs.errorsCameraFind.hide();
    },
    openSelectCameraModelModal() {
      this.hide();
      this.$emit('showSelectModal');
    },
  },
};
</script>

<style></style>
