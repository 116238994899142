<template>
  <div>
    <vue2-date-picker
      v-bind="$attrs"
      :editable="false"
      :formatter="format"
      :lang="lang"
      :value="value"
      ref="datePicker"
      @clear="clear"
      @input="onChange"
    >
      <template v-slot:input>
        <BFormGroupSlot
          :label="label"
          :value="inputValue"
          :has-error="isInvalid"
        >
          <b-input type="text" :value="inputValue" />
        </BFormGroupSlot>
      </template>
    </vue2-date-picker>
    <div class="d-none slot-input">
      <slot class="d-none"></slot>
    </div>
  </div>
</template>

<script>
import Vue2DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import dayjs from 'dayjs';
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';
import { getLocale } from '@/helpers';

Vue2DatePicker.locale(getLocale());

const stringifyDate = (val) => (val ? dayjs(val).format('LL') : null);
const parseDate = (val) => (val ? dayjs(val).toISOString() : null);
const formatToServer = (val) => dayjs(val).format('YYYY-MM-DD HH:mm:ss');

export default {
  name: 'DatePickerSlot',
  props: {
    label: {
      type: String,
    },
    autoSubmit: { type: Boolean, default: false },
  },
  components: {
    Vue2DatePicker,
    BFormGroupSlot,
  },
  data() {
    return {
      validates: true,
      format: {
        stringify: stringifyDate,
        parse: parseDate,
      },
      value: '',
      inputSlot: null,
    };
  },
  computed: {
    lang() {
      return {
        defaultLocale: 'ru',
        firstDayOfWeek: 1,
      };
    },
    inputValue() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          return this.value.map((val) => stringifyDate(val)).join(' - ');
        }
        return this.format.stringify(this.value);
      }
      return '';
    },
    isInvalid() {
      if (this.inputSlot) {
        return this.inputSlot.className.includes('is-invalid');
      }
      return null;
    },
  },
  mounted() {
    const [inputVNode] = this.$slots.default;
    if (inputVNode) {
      const input = inputVNode.elm.querySelector('input');
      if (input) {
        this.inputSlot = input;
        if (input.value) {
          this.value = new Date(input.value);
        }
      }
    }
  },
  methods: {
    onChange(val) {
      this.value = val;
      this.inputSlot.value = formatToServer(val);
      if (this.autoSubmit === true) {
        this.inputSlot.form.submit();
      }
    },
    clear() {
      this.inputSlot.value = null;
    },
  },
};
</script>
<style lang="scss">
$primary-color: #03a9f4;
@import '~vue2-datepicker/scss/index';

.mx-datepicker {
  width: 100%;
  input {
    background: none !important;
    cursor: pointer !important;
  }
  .mx-icon-calendar {
    margin-right: 10px;
  }
}
.mx-datepicker-main {
  .mx-date-row {
    td {
      border: none !important;
    }
  }
}
</style>
