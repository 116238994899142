<template>
  <div>
    <b-modal
      :title="$t('devices.modal.create')"
      no-stacking
      ref="acessCreate"
      @hide="hide"
    >
      <div class="modal-body-row with-cols text-center p-8">
        <div
          class="d-flex gap-2 align-items-center justify-content-center mb-3"
        >
          <i class="icon-local-ok fast-modal__icon fast-modal__icon_ok" />
          <p class="fast-modal__text mb-0">
            {{ $t('devices.modal.added.success.title') }}
          </p>
        </div>
        <p class="fast-modal__text">
          {{ $t('devices.modal.added.success.camera.text') }}
        </p>
      </div>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="openUuidSelectModal"
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.more_add') }}
          </b-button>
          <!-- <b-button
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
            @click="openSettingModal"
          >
            {{ $t('devices.modal.setting') }}
          </b-button> -->
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import EventBus from '@/packs/EventBus';
import { EVENTS } from '@/consts';

export default {
  name: 'AccessCreateCamera',
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapState('camera', ['camera']),
    ...mapGetters('camera', ['getCamera']),

    camera() {
      return this.getCamera;
    },
  },
  methods: {
    show() {
      this.$refs.acessCreate.show();
    },
    hide() {
      this.$refs.acessCreate.hide();
      // location.reload();
    },
    openSettingModal() {
      this.hide();
    },
    openUuidSelectModal() {
      this.hide();
      EventBus.$emit(EVENTS.camera.fast.modal.show);
    },
  },
};
</script>
