export default {
  preloader: {
    show: 'preloader::show',
    hide: 'preloader::hide',
  },
  callSession: {
    refresh: 'callSession::refresh',
  },
  intercom: {
    refresh: 'intercom:refresh', // Create/Update, refresh intercom counts
    update: 'intercom:update', // Update, refresh IntercomRightPanel and table
    fast: {
      modal: {
        show: 'intercomFastModal:show',
      },
    },
  },
  camera: {
    fast: {
      modal: {
        show: 'cameraFastModal:show',
      },
    },
  },
};
