<template>
  <div>
    <b-modal
      id="camera-selection-model-modal"
      class="camera-selection-model-modal"
      ref="SelectCamera"
      :title="$t('devices.modal.create')"
      size="sm"
      no-stacking
      @hidden="hide"
    >
      <b-form-validate
        ref="selectInfoCamera"
        :model="camera"
        :validations="validations"
      >
        <template slot-scope="{ validateState, v }">
          <div class="modal-body-row with-cols pt-30">
            <b-form-row class="mb-3">
              <b-col cols="12">
                <cameras-models-autocomplete
                  id="select-camera-modal_model_id"
                  object-property="id"
                  display-property="name"
                  :placeholder="$t('devices.modal.model')"
                  v-model="camera.cameras_model_id"
                  @select:item="selectModel"
                  :state="validateState('cameras_model_id')"
                  :camera-model-data="cameraModel"
                  class="mb-0"
                  @clear="resetCamera"
                />
                <b-error
                  v-if="!v.model.cameras_model_id.required && isSubmit"
                  :error="$t('devices.errors.not_empty')"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-3" v-if="cameraModel.mqtt_supported">
              <b-col cols="12">
                <b-input-label
                  :disabled="!camera.cameras_model_id"
                  :label="$t('devices.modal.uuid_or_serial')"
                  v-model="camera.uuid_or_serial"
                  :state="validateState('uuid_or_serial')"
                  class="mb-0"
                />
                <b-error
                  v-if="!v.model.uuid_or_serial.required && isSubmit"
                  :error="$t('devices.errors.not_empty')"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-3" v-if="!cameraModel.mqtt_supported">
              <b-col cols="12">
                <b-input-label
                  :disabled="!camera.cameras_model_id"
                  :label="$t('devices.modal.ip')"
                  v-model="camera.ip_address"
                  :state="validateState('ip_address')"
                  class="mb-0"
                />
                <b-error
                  v-if="!v.model.ip_address.required && isSubmit"
                  :error="$t('devices.errors.not_empty')"
                />
                <b-error
                  v-else-if="!v.model.ip_address.ipAddress && isSubmit"
                  :error="$t('devices.errors.formatter')"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-3" v-if="!cameraModel.mqtt_supported">
              <b-col cols="12">
                <b-input-label
                  :disabled="!camera.cameras_model_id"
                  :label="$t('devices.modal.mac')"
                  v-model="camera.macaddr"
                  :state="validateState('macaddr')"
                  class="mb-0"
                />
                <b-error
                  v-if="!v.model.macaddr.required && isSubmit"
                  :error="$t('devices.errors.not_empty')"
                />
              </b-col>
            </b-form-row>
            <!-- <b-form-row class="mb-3">
              <b-col cols="12">
                <b-input-label
                  :disabled="!camera.cameras_model_id"
                  :label="$t('devices.tabs.device.username')"
                  autocomplete="off"
                  v-model="camera.settings.username"
                  class="mb-0"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-3">
              <b-col cols="12">
                <b-input-label
                  :disabled="!camera.cameras_model_id"
                  :label="$t('devices.tabs.device.password')"
                  autocomplete="off"
                  v-model="camera.settings.password"
                  class="mb-0"
                />
              </b-col>
            </b-form-row> -->
            <b-form-row class="mb-3" v-if="cameraModel.channel_id_required">
              <b-col cols="12">
                <b-input-label
                  :label="$t('devices.modal.channel')"
                  autocomplete="off"
                  v-model="camera.channel_id"
                  class="mb-0"
                />
              </b-col>
            </b-form-row>
          </div>
        </template>
      </b-form-validate>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="openUuidSelectModal"
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.back') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto"
            @click="findDevice"
          >
            {{ $t('devices.modal.next') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <info-camera-modal
      ref="InfoCameraModal"
      id="camera-info"
      :clientId="clientId"
      @showSelectModal="show"
    />
    <errors-camera-find-modal
      ref="ErrorsCameraFindModal"
      id="camera-errors-find"
      :clientId="clientId"
      @showSelectModal="show"
    />
  </div>
</template>

<script>
import { EVENTS } from '@/consts';
import { required, requiredIf, ipAddress } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import CamerasModelsAutocomplete from '@/components/autocompletes/CamerasModelsAutocomplete';
import InfoCameraModal from './InfoCameraModal.vue';
import ErrorsCameraFindModal from './ErrorsCameraFindModal.vue';
import BError from '@/components/base/BError';
import { isEmpty } from 'lodash';

export default {
  name: 'SelectCameraModelModal',
  components: {
    CamerasModelsAutocomplete,
    InfoCameraModal,
    ErrorsCameraFindModal,
    BError,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isSubmit: false,
      onHide: false,
    };
  },
  computed: {
    ...mapState('camera', ['camera', 'cameraModel']),
    ...mapGetters('camera', ['getCamera', 'getCameraModel']),

    camera: {
      get() {
        return this.getCamera;
      },
      set(value) {
        this.setCamera(value);
      },
    },
    cameraModel: {
      get() {
        return this.getCameraModel;
      },
      set(value) {
        this.selectCameraModel(value);
      },
    },
    validations() {
      return {
        ip_address: {
          required: requiredIf(
            () => !this.cameraModel.mqtt_supported && !this.camera.macaddr,
          ),
          ipAddress,
        },
        uuid_or_serial: {
          required: requiredIf(() => this.cameraModel.mqtt_supported),
        },
        macaddr: {
          required: requiredIf(
            () => !this.cameraModel.mqtt_supported && !this.camera.ip_address,
          ),
        },
        cameras_model_id: {
          required,
        },
        channel_id: {
          required: requiredIf(() => this.cameraModel.channel_id_required),
        },
      };
    },
  },
  methods: {
    ...mapMutations('camera', [
      'selectCameraModel',
      'setCamera',
      'resetCamera',
    ]),
    ...mapActions('camera', ['validParamsCamera']),

    findDevice(event) {
      if (event) event.preventDefault();
      this.isSubmit = true;
      if (this.camera.uuid_or_serial) {
        this.camera.uuid_or_serial = this.camera.uuid_or_serial.trim();
      }
      if (this.camera.macaddr) {
        this.camera.macaddr = this.camera.macaddr.trim();
      }

      if (this.$refs.selectInfoCamera.validate()) {
        this.onHide = true;
        let params = {
          cameras_model_id: this.camera.cameras_model_id,
        };
        if (this.cameraModel.mqtt_supported) {
          params = Object.assign(params, {
            uuid_or_serial: this.camera.uuid_or_serial,
          });
        } else {
          if (this.camera.macaddr) {
            params = Object.assign(params, {
              macaddr: this.camera.macaddr,
            });
          }
          if (this.camera.ip_address) {
            params = Object.assign(params, {
              ip_address: this.camera.ip_address,
            });
          }
        }
        if (this.cameraModel.channel_id_required) {
          params = Object.assign(params, {
            channel_id: this.camera.channel_id,
          });
        }
        if (!isEmpty(params)) {
          this.validParamsCamera({ clientId: this.clientId, params: params })
            .then(() => {
              this.hide();
              this.openInfoCameraModal();
            })
            .catch((error) => {
              const errors = error.response.data.errors;

              this.hide();
              this.openErrorsCameraFindModal(errors);
            });
        } else {
          this.hide();
          this.openInfoCameraModal();
        }
      }
    },
    selectModel(val) {
      if (!isEmpty(this.cameraModel)) {
        if (this.cameraModel.mqtt_supported != val.mqtt_supported) {
          this.camera = Object.assign({}, this.camera, {
            uuid_or_serial: null,
            ip_address: null,
            macaddr: null,
          });
        }

        if (this.cameraModel.channel_id_required != val.channel_id_required) {
          this.camera.channel_id = null;
        }
      }

      this.selectCameraModel(val);
    },
    openInfoCameraModal() {
      this.$refs.InfoCameraModal.show();
    },
    openErrorsCameraFindModal(errors) {
      this.$refs.ErrorsCameraFindModal.show(errors);
    },
    show(resetSubmit = true) {
      this.$refs.SelectCamera.show();
      this.onHide = false;
      if (resetSubmit) {
        this.isSubmit = false;
      } else {
        if (this.isSubmit) {
          setTimeout(() => {
            this.$refs.selectInfoCamera.validate();
          }, 300);
        }
      }
    },
    hide() {
      if (this.onHide) {
        this.$refs.SelectCamera.hide();
      } else {
        this.$bvModal
          .msgBoxConfirm(`${this.$tc('devices.close.create')}`, {
            title: this.$t('devices.close.title'),
            okTitle: this.$t('devices.close.buttons.yes'),
            cancelTitle: this.$t('devices.close.buttons.no'),
            okVariant: 'danger',
            bodyClass: 'modal-body__confirm',
          })
          .then((res) => {
            if (res) {
              this.$refs.SelectCamera.hide();
            } else {
              this.show(false);
            }
          });
      }
    },
    openUuidSelectModal() {
      this.onHide = true;
      this.hide();
      EventBus.$emit(EVENTS.camera.fast.modal.show);
    },
  },
};
</script>

<style></style>
