<template>
  <div class="cameras-list" :key="cameras.length">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h5>{{ $t('barrier.cameras') }}</h5>
      <b-button
        class="b-button"
        variant="primary"
        @click="onAddCamera"
        :disabled="isDisabledAddBtn"
      >
        {{ $t('camera.add') }}
      </b-button>
    </div>
    <camera
      v-for="(camera, index) in cameras"
      :key="index"
      class="cameras-item mb-3 p-3"
      :index="index"
      :validated-relays="validatedRelays"
      :driving-directions="drivingDirections"
      :client-id="clientId"
      :cameras-ids="camerasIds"
      :disabled-remove="cameras.length == 1"
      @selectZone="openSelectZone"
      :cameras-errors="camerasErrors"
      :is-submit="isSubmit"
      :errors="errors"
    />
    <npr-camera-zone ref="nprCameraZone" @updateSelectZone="updateSelectZone" />
  </div>
</template>

<script>
import Camera from '@/vue_slim/manage_components/npr/form/Camera.vue';
import NprCameraZone from '@/components/modals/CameraZone.vue';
import i18n from '@app/i18n';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'CamerasList',
  components: {
    Camera,
    NprCameraZone,
  },
  props: {
    clientId: {
      type: [Number, String],
    },
    drivingDirections: {
      type: Array,
      default: null,
    },
    barrierId: {
      type: [Number, String],
      default: null,
    },
    geoUnitId: {
      type: [Number, String],
      default: null,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    validateState: {
      type: Function,
    },
    v: {},
  },
  created() {
    if (this.cameras.length == 0) {
      this.onAddCamera();
    }
  },
  computed: {
    ...mapState('barrier', ['cameras', 'relays']),
    ...mapGetters('barrier', ['getCameras', 'getRelays']),

    cameras: {
      get() {
        return this.getCameras;
      },
      set(value) {
        this.setCameras(value);
      },
    },

    relays: {
      get() {
        return this.getRelays;
      },
      set(value) {
        return this.setRelays(value);
      },
    },

    isDisabledAddBtn() {
      return this.cameras.length >= 4 ? true : false;
    },

    camerasErrors() {
      return this.v?.model?.cameras_attributes;
    },

    relaysList() {
      if (this.relays) {
        return this.relays.map((relay, index) => {
          return Object.assign({}, relay, {
            title: `${i18n.t('relay.title')} ${index + 1}`,
          });
        });
      } else {
        return [];
      }
    },

    validatedRelays() {
      return this.relaysList.filter(
        (relay) =>
          relay.id &&
          relay.npr_action == 'open' &&
          (relay.npr_barrier_id == this.barrierId || !relay.npr_barrier_id),
      );
    },

    camerasIds() {
      return this.cameras.map((camera) => {
        return camera.uuid;
      });
    },
  },
  watch: {
    relays: {
      immediate: false,
      deep: true,
      handler() {
        this.cameras.forEach((camera) => {
          camera.relay_id = this.validatedRelays.find(
            (relay) => relay.id == camera.relay_id,
          )
            ? camera.relay_id
            : null;
        });
      },
    },
  },
  methods: {
    ...mapMutations('barrier', [
      'setCameras',
      'onAddCamera',
      'setRelays',
      'setCamera',
    ]),

    openSelectZone(camera) {
      this.$refs.nprCameraZone.show(camera);
    },

    updateSelectZone(camera) {
      const idx = this.cameras.findIndex((el) => el.id == camera.id);

      this.setCamera({ index: idx, camera: camera });
    },
  },
};
</script>

<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';

.cameras-list {
  .cameras-item {
    display: flex;
    flex-direction: column;
    background: #fbfbfb;
    border-radius: 5px;
  }
  .btn-trash {
    color: $blue;
    &:hover {
      color: $white;
    }
  }
  .btn-add {
    &:not([disabled]) {
      border-color: $blue;
    }
  }

  &__btn {
    padding: 13px;
    color: $blue;
    font-weight: bold;
  }
}
</style>
