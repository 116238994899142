<template>
  <div>
    <b-modal
      ref="infoCreateFast"
      :title="$t('devices.modal.create')"
      no-stacking
      @hidden="hide"
    >
      <b-form-validate
        ref="createFastForm"
        :model="camera"
        :validations="validations"
      >
        <template slot-scope="{ validateState, v }">
          <div class="modal-body-row pt-30">
            <b-col cols="12" class="mb-3">
              <geo-units-autocomplete
                v-model="camera.geo_unit_id"
                :client-id="Number(clientId)"
                :placeholder="$t('devices.modal.address')"
                show-loading
                :state="validateState('geo_unit_id')"
                :additional-query-params="{
                  type_not_in: ['City', 'Street', 'District'],
                }"
                section="camera"
                class="w-100 mb-0 camera-autocompletes"
                :geo-unit="camera.geo_unit"
                @clear="camera.geo_unit = {}"
                @select:item="(value) => (camera.geo_unit = value)"
              ></geo-units-autocomplete>
              <b-error
                v-if="!v.model.geo_unit_id.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
            </b-col>
            <b-col cols="12">
              <BInputLabel
                :label="$t('devices.modal.name')"
                :state="validateState('name')"
                v-model="camera.name"
                class="mb-0"
              />
              <b-error
                v-if="!v.model.name.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
              <b-error
                v-if="!v.model.name.maxLength && isSubmit"
                :error="$t('devices.errors.max_length', [70])"
              />
            </b-col>
          </div>
        </template>
      </b-form-validate>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="openUuidSelectModal"
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.back') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto"
            @click="next"
          >
            {{ $t('devices.modal.next') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <access-create-camera
      ref="AccessCreateCamera"
      id="camera-access-create"
      :clientId="clientId"
    />
    <error-create-camera
      ref="ErrorCreateCamera"
      id="camera-error-create-modal"
      :clientId="clientId"
    />
  </div>
</template>

<script>
import { EVENTS } from '@/consts';
import { maxLength, required } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import EventBus from '@/packs/EventBus';
import ErrorCreateCamera from '../final/ErrorCreateCamera.vue';
import AccessCreateCamera from '../final/AccessCreateCamera.vue';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import BError from '@/components/base/BError';

export default {
  name: 'InfoCreateModal',
  components: {
    ErrorCreateCamera,
    GeoUnitsAutocomplete,
    AccessCreateCamera,
    BError,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isSubmit: false,
      onHide: false,
    };
  },
  computed: {
    validations() {
      return {
        name: {
          required,
          maxLength: maxLength(130),
        },
        geo_unit_id: {
          required,
        },
      };
    },
    ...mapState('camera', ['camera', 'cameraModel']),
    ...mapGetters('camera', ['getCamera', 'getCameraModel']),
    camera: {
      get() {
        return this.getCamera;
      },
      set(value) {
        this.setСamera(value);
      },
    },
    cameraModel() {
      return this.getCameraModel;
    },
  },
  methods: {
    ...mapMutations('camera', ['setСamera']),
    ...mapActions('camera', ['createCamera']),

    addDevice() {
      const cameraDevice = Object.assign({}, this.camera);
      delete cameraDevice.geo_unit;
      delete cameraDevice.matched_field;

      this.createCamera({
        clientId: this.clientId,
        cameras_device: cameraDevice,
      })
        .then((resp) => {
          this.onHide = true;
          this.hide();
          this.showAccessCreateCameraModal();
        })
        .catch((error) => {
          this.showErrorCreateCameraModal();
        });
    },

    hide() {
      if (this.onHide) {
        this.$refs.infoCreateFast.hide();
      } else {
        this.$bvModal
          .msgBoxConfirm(`${this.$tc('devices.close.create')}`, {
            title: this.$t('devices.close.title'),
            okTitle: this.$t('devices.close.buttons.yes'),
            cancelTitle: this.$t('devices.close.buttons.no'),
            okVariant: 'danger',
            bodyClass: 'modal-body__confirm',
          })
          .then((res) => {
            if (res) {
              this.$refs.infoCreateFast.hide();
            } else {
              this.show(false);
            }
          });
      }
    },
    openUuidSelectModal() {
      this.onHide = true;
      this.hide();
      EventBus.$emit(EVENTS.camera.fast.modal.show);
    },
    next(event) {
      if (event) event.preventDefault();
      this.isSubmit = true;

      if (this.$refs.createFastForm.validate()) {
        this.onHide = true;
        this.addDevice();
      }
    },

    show(resetSubmit = true) {
      this.$refs.infoCreateFast.show();
      this.onHide = false;
      if (resetSubmit) {
        this.isSubmit = false;
      } else {
        if (this.isSubmit) {
          setTimeout(() => {
            this.$refs.createFastForm.validate();
          }, 300);
        }
      }
    },
    showErrorCreateCameraModal() {
      this.$refs.ErrorCreateCamera.show();
    },
    showAccessCreateCameraModal() {
      this.$refs.AccessCreateCamera.show();
    },
  },
};
</script>

<style lang="scss">
.camera-create-form {
  .autocomplete-results-dropdown {
    margin-bottom: 0;
  }
}
</style>
